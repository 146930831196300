import React, { useContext } from 'react';
import { DeviceContext, DeviceState } from '../../context/deviceContext';
import { colors } from '../../style/genericStyle';
import appleStore from './../../static/images/App-Store-button.png';
import googlePlay from './../../static/images/Google-Play-button.png';



function StoreSection() {

    const device = useContext(DeviceContext).device
    
    return (
        <section style={container(device)}>
            <a href="https://apps.apple.com/es/app/bebelo/id1613322776">
                <img style={storeImage(device)} src={appleStore} alt="" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.beblo.map&hl=es&gl=es">
                <img style={storeImage(device)} src={googlePlay} alt="" />
            </a>
        </section>
    );
}

let container: (device: DeviceState) => React.CSSProperties = (device) => (
    {
        zIndex: 2,
        display: "flex",
        flex: 1,
        flexDirection: device.isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: device.isMobile ? "center" : "unset",
        color: colors.textPrimary,
        fontSize: "2.5em",
        gap: device.isMobile ? "0.5em" : "0.8em"
    }
)

let storeImage: (device: DeviceState) => React.CSSProperties = (device) => (
    {
        maxWidth: device.isMobile ? "225px" : "200px"
    }
  )



export default StoreSection;
