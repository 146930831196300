
interface Colors {
    primary: string,
    lightPrimary: string,
    darkPrimary: string,

    secondary: string,
    secondaryDark: string,

    textPrimary: string
    textSecondary: string

    redGlow: string
}

export let colors: Colors = {
    primary: "#04D1CD",
    lightPrimary: "#23FAF5",
    darkPrimary: "#038D8A",
    secondary: "#182333",
    secondaryDark: "#020202",
    textPrimary: "#FFFFFF",
    textSecondary: "#DEE3E3",
    redGlow: "#FF3A44"
}