import React, { useContext } from 'react';
import { DeviceContext, DeviceState } from '../../context/deviceContext';
import { colors } from '../../style/genericStyle';


interface Props {
    header: string,
    image: string,
    textOne: string,
    left?: boolean,
    textTwo?: string, 
    useTextChildren?: boolean
}

function ImageTextHorizontal(props: React.PropsWithChildren<Props>) {

    const device = useContext(DeviceContext).device
    
    return (
        <section style={container(device)}>
            <div style={{...itemImage(device), order: props.left ? 2 : 1}}>
                <img style={imageStyle(device)} src={props.image} alt="" />
            </div>
            <div style={{...itemText(device), order: props.left ? 1 : 2}}>
                {
                    props.useTextChildren ? (
                        props.children
                    ) : (
                        <>
                            <h3 style={header}>{props.header }</h3>
                            <p style={text}>{props.textOne}</p>
                            {
                                props.textTwo ? <p style={text}>{props.textTwo}</p> : undefined
                            }
                        </>
                    )
                }
            </div>
        </section>
    );
}

let container: (device: DeviceState) => React.CSSProperties = (device) => (
    {
        zIndex: 2,
        display: "flex",
        flex: 1,
        justifyContent: "center",
        color: colors.textPrimary,
        flexDirection: device.isMobile ? "column" : "row",
        gap: "0.8em",
        paddingLeft: !device.isMobile ? "5em" : "",
        paddingRight: !device.isMobile ? "5em" : "",
    }
)

let itemImage: (device: DeviceState) => React.CSSProperties = (device) => (
    {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 4
    }
)

let itemText: (device: DeviceState) => React.CSSProperties = (device) => (
    {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "2em",
        flex: 3,
        textAlign: device.isMobile ? "center" : "unset"
    }
)

let imageStyle: (device: DeviceState) => React.CSSProperties = (device) => (
    {
        maxHeight: device.isTablet ? "500px" : "700px",
        maxWidth: "80vw",
        marginBottom: device.isMobile ? "2em" : ""
    }
)

let header: React.CSSProperties = {
    color: colors.textPrimary, 
    fontSize:"2.5em", 
    marginBottom: "0.5em", 
    fontWeight: "600",
    marginTop: 0
}

let text: React.CSSProperties = {
    color: colors.textSecondary, 
    fontSize:"1.4em", 
    marginBottom: "0.8em", 
    fontWeight: "400"
}

export default ImageTextHorizontal;