import React, { useContext } from 'react';
import { DeviceContext, DeviceState } from '../context/deviceContext';

interface Props {
    top: number,
    mobileTop?: number,
    color: string,
    right?: boolean,
}

function Ball(props: Props) {

  const device = useContext(DeviceContext).device

  return (
    <div style={
      ballStyle(
        device, 
        props.mobileTop && device.isMobile ? props.mobileTop : props.top, 
        props.color, 
        props.right
      )
    }></div>
  );
}


let ballStyle: (device: DeviceState, top: number, color: string, right?: boolean) => React.CSSProperties = (device, top, color, right) => (
    {
        position: "absolute",
        width: "400px",
        height: device.isMobile ? "80vw" : "40vw",
        left: !right ? device.isMobile ? "-200px" : "-150px" : "unset",
        right: right ? device.isMobile ? "-200px" : "-150px" : "unset",
        top: `${top}px`,
        background: color,
        opacity: 0.5,
        borderRadius: "50%",
        filter: "blur(12vmax)",
        zIndex: -1
    }
)


export default Ball;