import React, { useContext } from 'react';
import { colors } from '../style/genericStyle';
import logo from './../static/images/Logo.png';
import MenuList from './MenuList';
import { Texts } from '../translations';
import appleStore from './../static/images/App-Store-button.png';
import googlePlay from './../static/images/Google-Play-button.png';
import { DeviceContext, DeviceState } from '../context/deviceContext';
import { Link } from 'react-router-dom';
import instagram from './../static/images/Instagram-icon.png';


function Footer() {

  const device = useContext(DeviceContext).device

  return (
    <div style={container}>
      <div style={upperContainer(device)}>
        <Link style={logoContainer(device)} to="/">
          <img src={logo} style={logoStyle} alt="" />
        </Link>
        { 
          device.isDesktop ? (
          <div style={menuContainer(device)}>
            <MenuList /> 
          </div> )
          : undefined 
        }
        {
          device.isMobile ? (
            <a href="https://www.instagram.com/bebelo.es/" rel="noreferrer" target="_blank" style={iconItem}>
              <img style={icon} src={instagram} alt="" />
              <p>{Texts.instagramVisit.es}</p>
            </a>
          ) : undefined
        }
        <div style={socialMediaContainer(device)}>
            <a href="https://apps.apple.com/es/app/bebelo/id1613322776">
                <img style={iconStyle} src={appleStore} alt="" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.beblo.map&hl=es&gl=es">
                <img style={iconStyle} src={googlePlay} alt="" /> 
            </a>
        </div>
      </div>
      {
        device.isDesktop ? (
        <div style={lowerContainer}>
          <div style={termsAndCondition}>
            <a style={{...link, marginRight: "2em"}} rel="noreferrer" target="_blank" href={"/files/Bebelo-Ltd-Terms-of-use.pdf"}>
              {Texts.footerOne.es}
            </a>
            <a style={link} rel="noreferrer" target="_blank" href={"/files/Bebelo-Ltd-Privacy-Policy.pdf"}>
              {Texts.footerTwo.es}
            </a>
          </div>
          <div style={copyright}>
            <p>{Texts.footerThree.es}</p>
          </div>
        </div> )
        : undefined
      }
    </div>
  );
}

let iconItem: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1em",
  color: colors.textPrimary,
  marginTop: "0.3em",
  marginBottom: "0.3em",
  fontSize: "1.3em",
  textDecoration: "none"
}

let icon: React.CSSProperties= {
  width: "40px",
  height: "40px"
}

let termsAndCondition: React.CSSProperties = {
  display: "flex",
  flex: 1,
  flexDirection: "row",
  width: "100%",
}

let link: React.CSSProperties = {
  color: colors.textPrimary,
  textDecoration: "none"
}

let copyright: React.CSSProperties = {
  display: "flex",
  flex: 1,
  justifyContent: "flex-end"
}

let container: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%"
}

let upperContainer: (device: DeviceState) => React.CSSProperties = (device) => (
  {
    display: "flex",
    flexWrap: "wrap-reverse",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: "1em",
  paddingBottom: "1em",
    flexDirection: device.isMobile ? "column-reverse" : "row",
    height: device.isMobile ? "17em" : "15em" ,
    paddingLeft: device.isMobile ? "0px" : "5em",
    paddingRight: device.isMobile ? "0px" : "5em",
    background: "radial-gradient(100% 100% at 50% 0%, rgba(3, 141, 138, 0.7) 0%, rgba(7, 74, 73, 0) 100%)"
  }
)

let lowerContainer: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: colors.secondaryDark,
  width: "100%",
  height: "7em",
  paddingLeft: "8em",
  paddingRight: "8em",
  color: colors.textPrimary
}

let iconStyle: React.CSSProperties = {
  height: "2.5em",
  marginLeft: "1em",
  marginRight: "1em"
}

let logoStyle: React.CSSProperties = {
  height: "4.5em"
}

let logoContainer: (device: DeviceState) => React.CSSProperties = (device) => (
  {
    display: "flex",
    flex: "1",
    alignItems: "center",
    justifyContent: !device.isDesktop ? "center" : "",
  }
)

let menuContainer: (device: DeviceState) => React.CSSProperties = (device) => (
  {
    display: "flex",
    flex: "4",
    justifyContent: "center",
    alignItems: "center"
  }
)

let socialMediaContainer: (device: DeviceState) => React.CSSProperties = (device) => (
  {
    display: "flex",
    flex: "1",
    justifyContent: !device.isDesktop ? "center" : "flex-end",
    alignItems: "center"
  }
)

export default Footer;