interface Translations {
    [key: string]: Translation
}

interface Translation {
    es: string
}

let translation: ({}: Translation) => Translation = x => x

export const Texts = {
    menuItemOne: translation({
        es: "App"
    }), 
    menuItemTwo: translation({
        es: "Bares"
    }), 
    menuItemThree: translation({
        es: "Brands"
    }), 
    menuItemFour: translation({
        es: "Mapa de bares"
    }),
    menuItemFive: translation({
        es: "Contacto"
    }),
    footerOne: translation({
        es: "Términos de uso"
    }),
    footerTwo: translation({
        es: "Política de datos"
    }),
    footerThree: translation({
        es: "© Copyright 2022 Bebelo Ltd"
    }), 
    startpageTitleMainFirst: translation({
        es: "Descarga la "
    }),
    startpageTitleMainSecond: translation({
        es: "app"
    }),
    startpageTitleSecondFirst: translation({
        es: "Precios de copas en "
    }),
    startpageTitleSecondSecond: translation({
        es: "Madrid"
    }),
    startpageNavigateOne: translation({
        es: "Bebelo Modo Live"
    }),
    startpageNavigateTwoPartOne: translation({
        es: "El mapa de precios de copas en Madrid es cómo empezamos, pero Bebelo nunca se trató solo de precios de copas."
    }),
    startpageNavigateTwoPartTwo: translation({
        es: 'Lo mejor de la app de Bebelo es el modo "Live". Ver qué bares hay abiertos, cuáles tienen mesa libre en terraza en tiempo real, cuáles están haciendo algo especial, cuáles están poniendo una tapa gratis, o cuáles están ofreciendo un descuento en las bebidas para que empiece la party / jaleo / sarao / movida ...'
    }),
    barpageHeader: translation({
        es: "Añade tu bar a Bebelo!"
    }),
    barpageOneHeader: translation({
        es: "Comunícate con toda la ciudad"
    }),
    barpageOneTextPartOne: translation({
        es: "Avisa a todo Madrid cuando hagáis algo divertido, pongáis una tapa, tenéis música en directo, o hagáis una promoción."
    }),
    barpageOneTextPartTwo: translation({
        es: "Queremos que seáis creativos: haz algo chulo y anúncialo a la ciudad en tiempo real"
    }),
    barpageTwoHeader: translation({
        es: "Como añadir tu bar"
    }),
    barpageTwoTextPartOne: translation({
        es: "Si eres el dueño o mánager de un bar puedes añadir tu bar al mapa de Bebelo."
    }),
    barpageTwoTextPartTwo: translation({
        es: "Únete al mapa más dinámico del mundo."
    }),
    barpageThreeHeader: translation({
        es: "Azoteas y terrazas"
    }),
    barpageThreeText: translation({
        es: "Da a conocer cuando tenéis una mesa libre en la terraza - en tiempo real."
    }),
    brandPageHeader:  translation({
        es: "Bebelo para brands"
    }),
    brandPageSubHeaderOne:  translation({
        es: "In English"
    }),
    brandPageSubHeaderTwo:  translation({
        es: "En Español"
    }),
    brandPageTextOnePartOne:  translation({
        es: "With a growing number of brands to choose from, it’s hard for consumers to know which is best for them."
    }),
    brandPageTextOnePartTwo:  translation({
        es: "We want to help great brands educate consumers on what makes their product special. You might be the next biggest gin, rum, whisky, vodka, liqueur or beer."
    }),
    brandPageTextTwoPartOne:  translation({
        es: "Con un número creciente de marcas para elegir, es difícil para los consumidores saber cuál es mejor para ellos."
    }),
    brandPageTextTwoPartTwo:  translation({
        es: "Queremos ayudar a las grandes marcas a educar a los consumidores sobre lo que hace que su producto sea especial. Podrías ser el siguiente gin, ron, whisky, vodka, licor o cerveza más importante."
    }),
    contactHeader:  translation({
        es: "Cómo contactarnos"
    }),
    contactText:  translation({
        es: "Si eres un bar, brand, la prensa o simplemente te va la marcha, puedes contactar con nosotros. "
    }),
    mapHeader:  translation({
        es: "Así empezó"
    }),
    mapDescription:  translation({
        es: "Probablemente ya no actualizaremos el mapa antiguo... Pero sigues pudiendo dar color a tu Google Maps, haciendo clic en la esquina."
    }),
    mapTextOne:  translation({
        es: "Todo empezó cuando unas cuantas personas iban de bar en bar anotando el precio de las copas en Madrid, pero Bebelo nunca se ha tratado solo de precios de copas"
    }),
    mapTextTwo: translation({
        es: "Se trataba de hacer la ciudad más divertida, de convertirla en un patio de recreo."
    }),
    mapTextThree: translation({
        es: "Aunque ya tenemos una app que es mucho mejor, nunca debes olvidar de dónde vienes. Así que como homenaje a nuestros primeros usuarios, que hicieron que todo esto sucediera, vamos a seguir compartiendo el mapa original."
    }), 
    instagramVisit: translation({
        es: "¡Síguenos en Instagram!"
    }), 
}