import React from 'react';
import Header from './Header';
import AppRouter from './AppRouter';
import Footer from './Footer';
import { colors } from '../style/genericStyle'
import { DeviceContext, DeviceState } from '../context/deviceContext';


function Layout() {
    return (
        <DeviceContext.Consumer>
            {({device}) => {
                return (
                    <div id="outer-container" style={container}>
                        <Header/>
                        <AppRouter/>
                        <Footer/>
                    </div>
                )
            }}
        </DeviceContext.Consumer>
    );
}

let container: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    maxWidth: "1200px",
    background: colors.secondary
}

export default Layout;
