import React, { useContext } from 'react';
import { DeviceContext, DeviceState } from '../../context/deviceContext';
import { colors } from '../../style/genericStyle';

interface Props {
    textMain: string,
    textSecond?: string,
    mainTextWordHighlight?: string,
    secondTextWordHighlight?: string
}

function PageTitle(props: Props) {

    const device = useContext(DeviceContext).device

    return (
        <section style={container(device)}>
            <h1 style={titleMain(device)}>
                {props.textMain}
                {
                    props.mainTextWordHighlight ?
                        <span style={{color: colors.primary }}>{props.mainTextWordHighlight}</span>    
                        :
                        undefined
                }
            </h1>
            {
                props.textSecond ?
                <h5 style={titleSecond(device)}>
                    {props.textSecond}
                    {
                        props.secondTextWordHighlight ?
                            <span style={{color: colors.primary }}>{props.secondTextWordHighlight}</span>    
                            :
                            undefined
                    }    
                </h5>
                :
                undefined
            }
        </section>
    );
}

let container: (device: DeviceState) => React.CSSProperties = (device) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingTop: device.isDesktop ? "1em" : "",
    color: colors.textPrimary,
    fontSize: "2.5em",
})

let titleMain: (device: DeviceState) => React.CSSProperties = (device) => (
    {
        marginBottom: 0,
        fontWeight: 500,
        fontSize: device.isMobile ? "1em" : "1.3em"
    }
)
    
let titleSecond: (device: DeviceState) => React.CSSProperties = (device) => (
    {
        marginTop: "1em",
        fontWeight: 400,
        fontSize: device.isMobile ? "0.5em" : "0.6em"
    }
)




export default PageTitle;
