import React, { useContext } from 'react';
import { DeviceContext, DeviceState } from '../../context/deviceContext';
import { colors } from '../../style/genericStyle';
import { Texts } from '../../translations';
import StoreSection from '../sections/storeSection';
import PageTitle from '../sections/titleSection';
import instagram from './../../static/images/Instagram-icon.png';


function MapPage() {
  const device = useContext(DeviceContext).device
  return (
    <div style={container(device)}>
    {/*   {
        !device.isMobile ? (
          <PageTitle textMain={Texts.mapHeader.es} />
        ) : undefined
      } */}
      <iframe title='map' style={{marginTop: device.isMobile ? "0em" : "2em", width: "50em", maxWidth: device.isMobile ? "90vw" : "80vw", height: "30em", border: "none"}} src="https://www.google.com/maps/d/u/0/embed?mid=1dEzTLwXnKLtml9Pude5qs5RX9yyDds4&ehbc=2E312F"></iframe>
      
      {
        device.isMobile ? (
          <div style={{marginTop: "2em", marginBottom: "1em"}}>
            <StoreSection />
            <a href="https://www.instagram.com/bebelo.es/" rel="noreferrer" target="_blank" style={iconItem}>
              <img style={icon} src={instagram} alt="" />
              <p>{Texts.instagramVisit.es}</p>
            </a>
          </div>
        ) : undefined
      }
      
      <div style={textContainer}>
       {/*  {
          !device.isMobile ? (
            <p style={{color: colors.darkPrimary, fontStyle: "italic", marginBottom: "3em", marginTop: 0, textAlign: "center"}}>{Texts.mapDescription.es}</p>
          ) : undefined
        } */}
        <p style={text}>{Texts.mapTextOne.es}</p>
        <p style={text}>{Texts.mapTextTwo.es}</p>
        <p style={text}>{Texts.mapTextThree.es}</p>
      </div>
    </div>
  );
}


let container: (device: DeviceState) => React.CSSProperties = (device) => ({
  display: "flex",
  flexDirection: "column",
  gap: device.isMobile ? "1em" : "2em",
  flex: "1",
  alignItems: "center",
  position: "relative",
  overflowX: "hidden",
  msOverflowX: "hidden",
  zIndex: 2,
  paddingBottom: "64px"
})

let textContainer: React.CSSProperties = {
  width: "40em",
  maxWidth: "80vw",
}

let text: React.CSSProperties = {
  color: colors.textSecondary, 
  fontSize:"1.4em", 
  marginBottom: "0.8em", 
  fontWeight: "400",
  textAlign: "center",
  maxWidth: "80vw"
}

let iconItem: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1em",
  color: colors.textPrimary,
  marginTop: "1.5em",
  fontSize: "1.3em",
  textDecoration: "none"
}

let icon: React.CSSProperties= {
  width: "50px",
  height: "50px"
}

export default MapPage;
