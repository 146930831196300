import React from 'react';
import { Routes, Route } from "react-router-dom";
import BarPage from './pages/barPage';

import BrandPage from './pages/brandPage';
import ContactPage from './pages/contactPage';
import MapPage from './pages/mapPage';
import StartPage from './pages/startPage';

function AppRouter() {
  return (
    <Routes>
      <Route path='/' element={ <MapPage/> } />
      <Route path='/bares' element={ <BarPage/> } />
      <Route path='/brands' element={ <BrandPage/> } />
      <Route path='/app' element={ <StartPage/> } />
      <Route path='/contacto' element={ <ContactPage/> } />
    </Routes>
  );
}

export default AppRouter;
