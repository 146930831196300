import React, { useContext } from 'react';
import { DeviceContext, DeviceState } from '../../context/deviceContext';
import { colors } from '../../style/genericStyle';
import { Texts } from '../../translations';
import Ball from '../ball';
import ImageTextHorizontal from '../sections/imageTextHorizontal';
import PageTitle from '../sections/titleSection';
import imageOne from './../../static/images/Brands-Copas.png';

function BrandPage() {
  const device = useContext(DeviceContext).device
  return (
    <div style={container(device)}>
      <Ball top={200} right color={colors.primary} />

      <PageTitle textMain={Texts.brandPageHeader.es} />
      <ImageTextHorizontal header={Texts.barpageOneHeader.es} left={device.isMobile ? false : true } image={imageOne} useTextChildren textOne={Texts.barpageOneTextPartOne.es}>
        <h3 style={{...header, color: colors.darkPrimary}}>{Texts.brandPageSubHeaderOne.es}</h3>
        <p style={text}>{Texts.brandPageTextOnePartOne.es}</p>
        <p style={text}>{Texts.brandPageTextOnePartTwo.es}</p>
        <h3 style={{...header, color: colors.darkPrimary, marginTop: "1em"}}>{Texts.brandPageSubHeaderTwo.es}</h3>
        <p style={text}>{Texts.brandPageTextTwoPartOne.es}</p>
        <p style={text}>{Texts.brandPageTextTwoPartTwo.es}</p>
      </ImageTextHorizontal>
    </div>
  );
}

let container: (device: DeviceState) => React.CSSProperties = (device) => ({
  display: "flex",
  flexDirection: "column",
  gap: device.isMobile ? "4em" : "8em",
  flex: "1",
  position: "relative",
  overflowX: "hidden",
  msOverflowX: "hidden",
  zIndex: 2,
  paddingBottom: "64px"
})

let header: React.CSSProperties = {
  color: colors.textPrimary, 
  fontSize:"2.5em", 
  marginBottom: "0.5em", 
  fontWeight: "600",
  marginTop: 0
}

let text: React.CSSProperties = {
  color: colors.textSecondary, 
  fontSize:"1.4em", 
  marginBottom: "0.8em", 
  fontWeight: "400"
}

export default BrandPage;
