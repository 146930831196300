import React, { useContext } from 'react';
import imageOne from './../../static/images/Bares-Double-Announcement-wider.png';
import imageTwo from './../../static/images/Bares-Add-your-bar.png';
import imageThree from './../../static/images/Bares-Double-Terraces.png';
import ImageTextHorizontal from '../sections/imageTextHorizontal';
import { Texts } from '../../translations';
import PageTitle from '../sections/titleSection';
import Ball from '../ball';
import { colors } from '../../style/genericStyle';
import { DeviceContext, DeviceState } from '../../context/deviceContext';


function BarPage() {
  const device = useContext(DeviceContext).device
  return (
    <div style={container(device)}>
      <Ball top={250} color={colors.primary} />
      <Ball top={1000} mobileTop={1600} right color={colors.primary} />
      <Ball top={1800} mobileTop={1000} color={colors.redGlow} />
      <PageTitle textMain={Texts.barpageHeader.es} />
      <ImageTextHorizontal header={Texts.barpageOneHeader.es} image={imageOne} textOne={Texts.barpageOneTextPartOne.es} textTwo={Texts.barpageOneTextPartTwo.es} />
      <ImageTextHorizontal header={Texts.barpageTwoHeader.es} left image={imageTwo} textOne={Texts.barpageTwoTextPartOne.es} textTwo={Texts.barpageTwoTextPartTwo.es} />
      <ImageTextHorizontal header={Texts.barpageThreeHeader.es} left={device.isMobile ? true : false} image={imageThree} textOne={Texts.barpageThreeText.es}/>
    </div>
  );
}

let container: (device: DeviceState) => React.CSSProperties = (device) => ({
  display: "flex",
  flexDirection: "column",
  gap: device.isMobile ? "4em" : "8em",
  flex: "1",
  position: "relative",
  overflowX: "hidden",
  msOverflowX: "hidden",
  zIndex: 2,
  paddingBottom: "64px"
})


export default BarPage;