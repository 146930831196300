import React, { Component } from 'react'
import { DeviceContext, DeviceState } from './deviceContext';

interface Props {}

interface State {
    device: DeviceState,
    changeDevice: (device: DeviceState) => void
}

export default class DeviceProvider extends Component<Props, State> {
    
    constructor(props: Props) {
        super(props)

        this.state = {
            device: this.getCurrentDevice(),
            changeDevice: this.changeDevice
        }

        window.addEventListener("resize", () => {
            let currentDevice = this.getCurrentDevice()
            
            if(JSON.stringify(currentDevice) !== JSON.stringify(this.state.device)) {
                this.changeDevice(currentDevice)
            } 
        })
    }

    getCurrentDevice = () => {
        if(window.innerWidth > 1007) {
            return {isDesktop: true} as DeviceState
        } else if(window.innerWidth <= 1007 && window.innerWidth > 640) {
            return {isTablet: true} as DeviceState
        } else {
            return {isMobile: true} as DeviceState
        }
    }

    changeDevice = (device: DeviceState) => {
        this.setState({device: device}, () => {
            console.log(this.state)
        })
    }
    
    render() {
        return (
            <DeviceContext.Provider value={this.state}>
                {this.props.children}
            </DeviceContext.Provider>
        )
    }
}
