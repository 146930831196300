import React, { useContext } from 'react';
import { DeviceContext, DeviceState } from '../../context/deviceContext';
import { colors } from '../../style/genericStyle';
import { Texts } from '../../translations';
import PageTitle from '../sections/titleSection';
import instagram from './../../static/images/Instagram-icon.png';
import whatsapp from './../../static/images/Whatsapp-icon.png';
import email from './../../static/images/Email-icon.png';



function ContactPage() {
  const device = useContext(DeviceContext).device
  return (
    <div style={container(device)}>
      <PageTitle textMain={Texts.contactHeader.es} />
      <p style={text}> {Texts.contactText.es} </p>
      <div>
        <a href="https://www.instagram.com/bebelo.es/" rel="noreferrer" target="_blank" style={iconItem}>
          <img style={icon} src={instagram} alt="" />
          <p>@bebelo.es</p>
        </a>
        <a href='https://api.whatsapp.com/send?phone=+34671159698' rel="noreferrer" target="_blank" style={iconItem}>
          <img style={icon} src={whatsapp} alt="" />
          <p>+34 671 15 96 98</p>
        </a>
        <a href='mailto:bebelo.es@gmail.com' target="_blank" rel="noreferrer" style={iconItem}>
          <img style={icon} src={email} alt="" />
          <p>bebelo.es@gmail.com</p>
        </a>
      </div>
    </div>
  );
}

let container: (device: DeviceState) => React.CSSProperties = (device) => ({
  display: "flex",
  flexDirection: "column",
  gap: device.isMobile ? "1em" : "3em",
  flex: "1",
  alignItems: "center",
  position: "relative",
  overflowX: "hidden",
  msOverflowX: "hidden",
  zIndex: 2,
  paddingBottom: "5em"
})

let iconItem: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  gap: "2em",
  color: colors.textPrimary,
  marginTop: "2em",
  fontSize: "1.3em",
  textDecoration: "none"
}

let icon: React.CSSProperties= {
  width: "60px",
  height: "60px"
}

let text: React.CSSProperties = {
  color: colors.textSecondary, 
  fontSize:"1.4em", 
  marginBottom: "0.8em", 
  fontWeight: "400",
  textAlign: "center",
  maxWidth: "80vw"
}

export default ContactPage;
