import React, { useContext, useState } from 'react';
import { Texts } from './../translations';
import { colors } from './../style/genericStyle'
import { Link } from 'react-router-dom';
import { DeviceContext, DeviceState } from '../context/deviceContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { slide as Menu } from 'react-burger-menu'

interface Props {
    collapsedEnabled?: boolean
}

function MenuList(props: Props) {

    const device = useContext(DeviceContext).device

    const [modalOpen, setModalOpen] = useState(false);

    if(props.collapsedEnabled && device.isMobile) {
        return (
            <>
                <Menu 
                isOpen={modalOpen} 
                onClose={() => { setModalOpen(false) }} 
                customBurgerIcon={false} 
                width={"100%"} 
                right
                disableAutoFocus
                >
                    <div style={menuItem(device)}>
                        <Link onClick={() => setModalOpen(false)} style={linkStyle} to="/app">{Texts.menuItemOne.es}</Link>
                    </div>
                    <div style={menuItem(device)}>
                        <Link onClick={() => setModalOpen(false)} style={linkStyle} to="/bares">{Texts.menuItemTwo.es}</Link>
                    </div>
                    <div style={menuItem(device)}>
                        <Link onClick={() => setModalOpen(false)} style={linkStyle} to="/brands">{Texts.menuItemThree.es}</Link>
                    </div>
                    <div style={{ ...menuItem(device), color: colors.primary }}>
                        <Link onClick={() => setModalOpen(false)} style={linkStyle} to="/">{Texts.menuItemFour.es}</Link>
                    </div>
                    <div style={{ ...menuItem(device), color: colors.primary }}>
                        <Link onClick={() => setModalOpen(false)} style={linkStyle} to="/contacto">{Texts.menuItemFive.es}</Link>
                    </div>
                </Menu>
                <FontAwesomeIcon onClick={() => { 
                    setModalOpen(true) 
                    console.log(modalOpen)
                }} style={iconStyle} icon={faBars}/>
            </>
        )
    } 

    return (
        <>
            <div style={menuItem(device)}>
                <Link style={linkStyle} to="/app">{Texts.menuItemOne.es}</Link>
            </div>
            <div style={menuItem(device)}>
                <Link style={linkStyle} to="/bares">{Texts.menuItemTwo.es}</Link>
            </div>
            <div style={menuItem(device)}>
                <Link style={linkStyle} to="/brands">{Texts.menuItemThree.es}</Link>
            </div>
            <div style={{ ...menuItem(device), color: colors.primary }}>
                <Link style={linkStyle} to="/">{Texts.menuItemFour.es}</Link>
            </div>
            <div style={{ ...menuItem(device), color: colors.primary }}>
                <Link style={linkStyle} to="/contacto">{Texts.menuItemFive.es}</Link>
            </div>
        </>
    );
}


let iconStyle: React.CSSProperties = {
    color: colors.textPrimary,
    cursor: "pointer",
    zIndex: 1001
}

let linkStyle: React.CSSProperties = {
    textDecoration: "none",
    color: colors.textPrimary
}

let menuItem: (device: DeviceState) => React.CSSProperties = (device) => ({
    marginLeft: "1em",
    marginRight: "1em",
    marginBottom: device.isMobile ? "1em" : "0px",
    fontSize: device.isMobile ? "1.5em" : undefined
})

export default MenuList;