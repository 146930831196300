import React, { useContext } from 'react';
import { colors } from '../../style/genericStyle';
import { Texts } from '../../translations';
import StoreSection from '../sections/storeSection';
import PageTitle from '../sections/titleSection';
import leftPhone from './../../static/images/App-3-screens-left.png';
import centerPhone from './../../static/images/App-3 screens-middle.png';
import rightPhone from './../../static/images/App-3-screens-right.png';
import { DeviceContext, DeviceState } from '../../context/deviceContext';
import Ball from '../ball';
import instagram from './../../static/images/Instagram-icon.png';


function StartPage() {

  const device = useContext(DeviceContext).device
  
  return (
    <div style={container}>
        <Ball top={300} color={colors.primary} />
        <Ball top={600} mobileTop={450} right color={colors.redGlow} />
        <PageTitle 
          textMain={Texts.startpageTitleMainFirst.es} 
          mainTextWordHighlight={Texts.startpageTitleMainSecond.es} 
          textSecond={Texts.startpageTitleSecondFirst.es} 
          secondTextWordHighlight={Texts.startpageTitleSecondSecond.es} />
        <StoreSection />
        {
          device.isMobile ? (
            <a href="https://www.instagram.com/bebelo.es/" rel="noreferrer" target="_blank" style={iconItem}>
              <img style={icon} src={instagram} alt="" />
              <p>{Texts.instagramVisit.es}</p>
            </a>
          ) : undefined
        }
        <section style={imageSection(device)}>
          <div style={imageContainer}>
            <img style={{...image, height: "90%"}} src={leftPhone} alt="" />
          </div>
          <div style={imageContainer}>
            <img style={image} src={centerPhone} alt="" />
          </div>
          <div style={imageContainer}>
            <img style={{...image, height: "90%"}} src={rightPhone} alt="" />
          </div>
        </section>
        <section style={navigateSection(device)}>
          <h1 style={{...navigateText, color: colors.textPrimary, fontSize:"2.5em", marginBottom: "1em", fontWeight: "600"}}>{Texts.startpageNavigateOne.es}</h1>
          <h3 style={{...navigateText, color: colors.textSecondary, fontSize:"1.4em", marginBottom: "1.2em", fontWeight: "400"}}>{Texts.startpageNavigateTwoPartOne.es}</h3>
          <h3 style={{...navigateText, color: colors.textSecondary, fontSize:"1.4em", fontWeight: "400"}}>{Texts.startpageNavigateTwoPartTwo.es}</h3>
        </section>
    </div>
  );
}


let container: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "2em",
  flex: "1",
  position: "relative",
  overflowX: "hidden",
  msOverflowX: "hidden",
  zIndex: 2,
}

let iconItem: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1em",
  color: colors.textPrimary,
  marginTop: "0.5em",
  marginBottom: "1em",
  fontSize: "1.3em",
  textDecoration: "none"
}

let icon: React.CSSProperties= {
  width: "50px",
  height: "50px"
}

let imageSection: (device: DeviceState) => React.CSSProperties = (device) => (
  {
    display: "flex",
    flex: "1",
    paddingLeft: device.isMobile ? "0.5em" : "3em",
    paddingRight: device.isMobile ? "0.5em" : "3em",
    paddingTop: device.isMobile ? "0px" : "6em",
    gap: device.isMobile ? "1em" : "5em",
  }
)


let navigateSection: (device: DeviceState) => React.CSSProperties = (device) => (
  {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "4em",
    paddingLeft: device.isMobile ? "3em" : "12em",
    paddingRight: device.isMobile ? "3em" : "12em",
    lineHeight: "35px",
    letterSpacing: "0.2px",
    marginBottom: "4em",
    textAlign: "center",
    zIndex:2,
  }
)

let navigateText: React.CSSProperties = {
  margin: "0px",
  marginBottom: "20px"
}

let imageContainer: React.CSSProperties = {
  display: "flex",
  flex: "1",
  alignItems:"flex-end"
}

let image: React.CSSProperties = {
  display: "flex",
  flex: "1",
  maxWidth: "100%",
  zIndex: 2
}

let versionText: React.CSSProperties = {
  display: "flex",
  flex: "1",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  fontSize: "1.7em",
  color: colors.textPrimary,
}




export default StartPage;
