import { createContext } from 'react'

export interface DeviceState {
    isMobile?: boolean
    isTablet?: boolean
    isDesktop?: boolean
}

export const DeviceContext = createContext({
    device: {} as DeviceState, // default
    changeDevice: (device: DeviceState) => {}, // default
})