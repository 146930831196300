import React, { useContext } from 'react';
import MenuList from './MenuList';
import logo from './../static/images/Logo.png';
import { DeviceContext, DeviceState } from '../context/deviceContext';
import { Link } from 'react-router-dom';


function Header() {

  const device = useContext(DeviceContext).device

  return (
    <div style={container(device)}>
      <Link style={logoContainer} to="/">
          <img src={logo} style={logoStyle} alt="" />
      </Link>
      <div style={menuContainer}>
        <MenuList collapsedEnabled />
      </div>
    </div>
  );

}

let container: (device: DeviceState) => React.CSSProperties = (device) => (
  {
    display: "flex",
    width: "100%",
    height: "10em",
    paddingLeft: device.isMobile ? "2em" : "5em",
    paddingRight: device.isMobile ? "2em" : "3em",
  }
)

let logoStyle: React.CSSProperties = {
  height: "4.5em"
}

let menuContainer: React.CSSProperties = {
  display: "flex",
  flex: "2",
  justifyContent: "flex-end",
  alignItems: "center"
}

let logoContainer: React.CSSProperties = {
  display: "flex",
  flex: "1",
  alignItems: "center"
}

export default Header;