import React from 'react';
import DeviceProvider from '../context/deviceProvider';
import Layout from './Layout';
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <DeviceProvider>
      <BrowserRouter>
        <div style={mainContainer}>
          <Layout />
        </div>
      </BrowserRouter>
    </DeviceProvider>
  );
}

let mainContainer: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "black"
}

export default App;
